<template>
	<main id="main">
		<div class="ncl-content">
			<a href="https://www.nextcollegiateleague.com" target="_blank">
				<img :src="nclImage" style="width:100%" alt="ncl">
			</a>
		</div>
	</main>
</template>

<script>
export default {
  name: 'ncl',
   props: {
    nclImage: { type: String, default: require('../../assets/images/frontend/ncl.png') }
  },
}
</script>
